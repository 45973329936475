import React from "react"

import { Heading } from "grommet";
import Dashboard from '../components/Dashboard'

const DashboardPage = () => {
  if (typeof window === "undefined") return null
  return <>
    <Heading level={1}>Dashboard</Heading>
    <Dashboard />
  </>
}

export default DashboardPage
