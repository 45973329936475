import React from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { colors } from "../../../theme"
import styled from "styled-components"
import { XAxis, YAxis, Bar, CartesianGrid, Legend, BarChart, Tooltip } from "recharts"
import { Heading } from "grommet"

const GET_NB_STUDENTS = gql`
  query getNbStudents($from: String) {
    tutoringStudentsNumbers(from: $from) {
      date
      total
    }
  }
`

const Wrapper = styled.div`
  > * > div {
    display: flex;
    justify-content: space-around;
  }
`


const StudentsChart = () => {
  const {
    loading,
    data,
  } = useQuery(GET_NB_STUDENTS, {
    variables: {
      from: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10)
    }
  })

  if (loading) return null
  const sanitizedData = data.tutoringStudentsNumbers.sort((a, b) => a.date - b.date).map((s) => ({
    ...s,
    date: Intl.DateTimeFormat('en-gb', {month: "numeric", day: "numeric"}).format(s.date)
  }))
  
  return (
    <Wrapper>
      <Heading level="3">New students per day</Heading>
      <BarChart width={window.innerWidth - 240} height={300} data={sanitizedData}>
        <XAxis dataKey="date"/>
        <YAxis />
        <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
        <Tooltip />
        <Bar dataKey="total" stackId="a" fill={colors.green} />
        <Legend />
      </BarChart>
    </Wrapper>
  )
}

StudentsChart.propTypes = {}

StudentsChart.defaultProps = {}

export default StudentsChart
