import React from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { colors } from "../../../theme"
import styled from "styled-components"
import { XAxis, YAxis, Bar, CartesianGrid, Legend, BarChart, Tooltip } from "recharts"
import { Heading } from "grommet"

const GET_NB_CUSTOMERS = gql`
  query getNbCustomers {
    parentSubscriptions {
      date
      past_due
      active
      trialing
      cancelled
    }
  }
`

const Wrapper = styled.div`
  > * > div {
    display: flex;
    justify-content: space-around;
  }
`

const Chart = props => {
  const {
    loading,
    data,
  } = useQuery(GET_NB_CUSTOMERS, {})

  if (loading || !data) return null
  const sanitizedData = data.parentSubscriptions.sort((a, b) => a.date - b.date).map((s) => ({
    ...s,
    date: Intl.DateTimeFormat('en-gb', {month: "numeric", day: "numeric"}).format(s.date)
  }))
  
  return (
    <Wrapper>
      <Heading level="3">Parent subscription updates</Heading>
      <BarChart width={window.innerWidth - 240} height={300} data={sanitizedData}>
        <XAxis dataKey="date"/>
        <YAxis />
        <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
        <Tooltip />
        <Bar dataKey="active" stackId="a" fill={colors.green} />
        <Bar dataKey="past_due" stackId="a" fill="#F3B910" />
        <Bar dataKey="trialing" stackId="a" fill={colors.indigo} />
        <Bar dataKey="cancelled" stackId="a" fill={colors.red} />
        <Legend />
      </BarChart>
    </Wrapper>
  )
}

Chart.propTypes = {}

Chart.defaultProps = {}

export default Chart
