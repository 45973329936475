import React from 'react'
import { Box, Grid } from 'grommet';
import ParentsChart from './components/ParentsChart';
import StudentsChart from './components/StudentsChart';

const DashboardPage = (props) => {
  return <Box>
    <Grid gap="small">
      <ParentsChart />
      <StudentsChart />
    </Grid>
  </Box>
}

DashboardPage.propTypes = {

}

DashboardPage.defaultProps = {

}

export default DashboardPage
